import * as React from 'react'
import Layout from '../components/layout'
import MyBlock from '../components/block'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const GalleryPage = () => {
  const data = useStaticQuery(graphql`
query MyQuery {
  allFile(
    filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: ""}}
  ) {
    nodes {
      publicURL
    }
  }
}
`)
	return (
		<Layout pageTitle="Contact us">
			<MyBlock title="Gallery" icon="image" bg="white">

            <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        //onSwiper={(swiper) => console.log(swiper)}
        //onSlideChange={() => console.log('slide change')}
      >
        {data.allFile.nodes.map(item => (
        <SwiperSlide><img style={{height:"80vh"}} src={item.publicURL}></img></SwiperSlide>
        )
        )}
      </Swiper>

			</MyBlock>
		</Layout >
	)
}



    export default GalleryPage
